import BaseAction from '@/actions/base_action.js'

export default class extends BaseAction {
  execute(properties, component) {
    const promise = $tpu.r.checkItems.destroy(
      properties.cardId,
      properties.checklistId,
      properties.checkItemId)
    this.executeCardUpdate(promise, properties, component, false)
  }
}
